.wrapper-container-login {
  background: url(../../../assets/images/bg-login.jpg) center top no-repeat;
  background-size: cover;
  padding: 3em 0 5em 0;
  height: 100vh;
  padding-bottom: 150px;
}

/****************************/
.container-login {
  max-width: 400px; /*OLD 725px change*/
  padding: 1em;
  background-color: #ececec;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
  color: #0f3152;
  border: 1px solid #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;

  display: -webkit-box; 
        display: -webkit-flex;  
        display: -ms-flexbox; 
        display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
}

/* Avatar */
.container-login_double-border {
  border-radius: 50%;
  background-color: transparent;
  border: solid 4px transparent;
  padding: 3px;
  /* width: 85px;
  height: 85px; */
  display: inline-block;
  vertical-align: middle;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #B5DFE9 50%,#E88B19 50%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container-login_double-border img {
  border-radius: 50%;
  width: 85px;
  box-shadow: 0 0 0 3px #798EAF;
}

/* Container */

.container-login_title {
  font-size: 20px;
  color: #0F3152;
  margin-top: 1em;
  font-weight: 600;
}

.container-login input {
  border: 1px solid #FFF;
  padding: 8px 15px;
  font-size: 18px;
  box-sizing : border-box;
  width: 100%;
  margin: 0.5em 0;
  border-radius: 6px;
  background-color: #FFF;
}

.container-login input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-login input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
}

.container-login input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
}

.container-login_btn-login {
  font-weight: bold;
  display: block;
  border-radius: 4px;
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  margin: 0.5em auto;
  background: linear-gradient(275.61deg,#ab863d 21.43%,#b4904a 58.77%,#dbb977 122.13%)!important;
  border: 0px solid #F2AF2B;
  color: #FFF;
  width: 100%;
  cursor: pointer;
}

.container-login_btn-login:hover {
  background: linear-gradient(95deg,#ab863d 29.43%,#b4904a 58.77%,#dbb977 177.13%)!important;
  border: 0px solid #b3bcbf;
}

.container-login_checkbox input {
  width: auto;
  cursor: pointer;
}

.container-login_checkbox {
  text-align: left;
  color: #FFF;    
}

.container-login_checkbox label {
  padding-left: 0.5em;
  cursor: pointer;
}

a.container-login_txt-secondary {
  margin-top: 2em;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  color: #F5C362;
  text-decoration: none;
}

a.container-login_txt-secondary:hover {
  text-decoration: underline;
}

/*new*/
.col-login-left {
  width: 98%;
}

.col-login-right {
  width: 48%;
  background-color: #EFF0F0;
  border-radius: 15px;
}

.module-w-padding {
  padding: 2em;
}

.module-login--teacher, 
.module-login--subscribe, 
.module-login--institutional {
  padding: 0.25em 1.5em 1em 1.5em;
}

.module-login--teacher {
  background-color: #E5E5E5;
}

.container-login .col-login-right h1 {
  color: #233B58;
  font-size: 18px;
  font-weight: normal;
  margin: 0.25em;
}

.container-login .col-login-right a {
  font-size: 15px;
  color: #5A6576;
  font-weight: bold;
}

.container-login .col-login-right .txt-small {
  font-size: 12px;
  margin: 0.8em 0 0 0;
  padding: 0;
  color: #233B58;
}

.module-login--institutional p {
  font-size: 12px;
}

.container-login .col-login-right img {
  width: 80px;
  height: auto;
  vertical-align: top;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .container-login { max-width: 85%; }
  .container-login_double-border { width: 80px; height: 80px; }
  
  .container-login_double-border img { width: 66px; }

  .container-login_title { font-size: 16px; margin-top: 0.5em;}
  
  .container-login input { padding: 10px 10px; font-size: 15px; }

  .container-login_btn-login { font-size: 14px; }

  .container-login_txt-secondary, .container-login_checkbox label { font-size: 14px;}
}

/* MQ 600px */

@media screen and (max-width: 600px) {
  .col-login-left, .col-login-right {
       width: 100%;
   }

   .module-w-padding {
       padding: 0.5em 2em;
   }
}

/* MQ 500px */

@media screen and (max-width: 500px) {   
   .container-login .col-login-right h1 { font-size: 16px; }
   .container-login .col-login-right img { width: 60px }
}

/* MQ 450px */

@media screen and (max-width: 450px) {
   .module-w-padding { padding: 0.5em 1em; }
}