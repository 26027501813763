.wrapper-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
}

/****************************/

.main-header {
    background: #33646F;
    padding: 0.2em 1em 0.4em 1em;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

/* .main-header_GenialSkills-logo {
    width: 20%;
} */

.main-header_GenialSkills-logo img {
    /* width: 105px; */
    height: 50px;
}


/* Top Bar */

.main-header_top-bar-menu {
    width: 80%;
    text-align: right;
}

.top-bar-menu {
    margin-right: 250px;
    font-size: 16px;
    list-style: none;
}

.top-bar-menu li, .top-bar-menu li img {
    display: inline-block;
    vertical-align: middle;
}

.top-bar-menu li .fas {
    margin-right: 5px;
    font-size: 20px;
    color: #FFF;
    vertical-align: middle;
}

.top-bar-menu li a {
    color: #FFF;
    text-decoration: none;
}

.top-bar-menu li a:hover {
    color: #FCAD2F;
}



/* Btn General */
.user-comp_profile-wrapper {
    width: auto;
    font-family: 'Roboto' , Arial , Helvetica , sans-serif;
    position: absolute;
    top: 5px;
    right: 10px;
    margin: 0;
    padding: 0;
    text-align: left;
}

.user-comp_profile-wrapper li {
    list-style: none;
}

.user-comp_profile {
    padding: 5px;
    text-align: right;
}
.user-comp_profile:hover {
    cursor:pointer;
}

.user-menu-icon {
    display: inline-block;
    vertical-align: middle;
    padding-right: 0.5em;
}

.user-comp_profile .user-name {
    font-size: 18px;
    color: #FFF;
    line-height: 20px;
    margin-left: 10px;
    font-weight: bold;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    sans-serif;
}

/* Avatar Principal */
.double-border--principal {
    border-radius: 50%;
    background-color: transparent;
    border: solid 3px transparent;
    padding: 3px;
    display: inline-block;
    vertical-align: middle;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #809CB7 50%,#AB863D 50%);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.double-border--principal img {
    border-radius: 50%;
    width: 30px;
    box-shadow: 0 0 0 3px #33646F;
}

/* hover profile show menu */
.user-comp_profile:hover .user-comp_menu {
    display: block;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
    .top-bar-menu {
        margin-right: 120px;
        font-size: 14px;
    }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
    .top-bar-menu span {display: none;}
    .top-bar-menu { margin-right: 100px; }

}



