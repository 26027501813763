.wrapper-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
}

/****************************/


.main-footer-students {
  padding: 1em ;
  text-decoration: none;
  color: #0F3152;
  background-color: #E2E4E4;
  font-size: 13px; 

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;

    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.footer-languages_languages-students {
  margin: 0;
  padding: 0;
}

.footer-languages_languages-students li {
    display: inline-block;
    padding: 0 0.25em;
    vertical-align: middle;
}


.footer-languages_languages-students li a {
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
}

.footer-languages_languages-students li a:hover {
    text-decoration: none;
}

.col-right--copyright .red-link {
  color: #EF1A1A
}

.col-right--copyright i {
  display: none;
}

.col-right--copyright a {
  cursor: pointer;
}



/* MQ 750px */

@media screen and (max-width: 750px) {
  .col-right--copyright, .col-left--footer-laguages {
      font-size: 12px;
  }


}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .footer-languages_languages-students span, .footer-languages_languages-students .fas { display: none;}
  .footer-languages_languages-students { text-transform: uppercase;}
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .col-right--copyright span {
    display: none;
  }

  .col-right--copyright i {
    display: inline-block;
    padding: 0 0.5em;
  }
}


/* .main-footer-students {
  background-color: #2C3140;
  color: #FFF;
  font-size: 15px;
  text-align: center;
}

.footer-languages-students ul, .footer-languages-students p {
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.footer-languages-students ul {text-align: left; margin: 0; padding: 0;}
.footer-languages-students p {text-align: right; }



.footer-languages-students {
padding: 0 0.5em;
}

.footer-languages_languages-students li {
display: inline-block;
padding: 0 0.25em;
vertical-align: middle;
}


.footer-languages_languages-students li a {
color: #FFF;
text-decoration: underline;
}

.footer-languages_languages-students li a:hover {
text-decoration: none;
}



@media screen and (max-width: 750px) {
  .footer-languages-students ul, .footer-languages-students p {
      font-size: 12px;
  }
}



@media screen and (max-width: 500px) {
  .footer-languages_languages-students span, 
  .footer-languages_languages-students .fas { display: none;}
  .footer-languages_languages-students { text-transform: uppercase;}

  .footer-languages-students ul {width: 29%;}
  .footer-languages-students p {width: 68%; }
} */
/* Footer Principal */

/* .footer-languages-students p {
  margin: 0.25em;
}

.footer-languages {
  padding: 0.5em;
}

.footer-languages_languages {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-languages_languages li {
  display: inline-block;
  padding: 0 0.25em;
  vertical-align: middle;
}

.footer-languages_languages li img {
  width: 25px;
}

.footer-languages_languages li a {
  color: #FFF;
  text-decoration: underline;
}

.footer-languages_languages li a:hover {
  text-decoration: none;
} */

/* MQ 750px */

/* @media screen and (max-width: 750px) {
  .footer-languages ul, .footer-languages p {
      display: inline-block;
      font-size: 12px;
  }

  .footer-languages_languages li img {
      width: 20px;
  }

  .footer-languages ul {text-align: left; width: 48% }
  .footer-languages p {text-align: right; }
  .footer-languages {  padding: 0.25em; }
} */
