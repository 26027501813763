.btn-Microsoft {
    font-weight: 700;
    display: block;
    border-radius: 4px;
    padding: 10px 0;
    font-size: 16px;
    text-align: center;
    margin: .5em auto;
    background: #FFF;
    border: 1px solid #737373;
    color: #737373;
    width: 100%;
    cursor: pointer;
}

.btn-Microsoft:hover {background: #F9F9F9;}

.btn-Microsoft img {width: 20px; vertical-align: middle; margin-right: 5px;}