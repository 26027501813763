.title{
    font-weight: 800 !important;
    font-size: 20px !important;
    margin: 0 0 1.5em !important;
    border-bottom: 1px solid #ddd !important;
    padding-bottom: .5em !important;
}

.btn-success{
    border-radius: 0 !important;
    border-color: #548342 !important;
    background-color: #548342 !important;
}

.btn-outline-secondary{
    border-color: gray !important;
    background-color: transparent !important;
    color: gray !important;
}

.margin-top-30{
    margin-top: 30px !important;
}

.center-item{
    margin: auto;
}

.gray-text{
    color: rgb(204, 204, 204);
}

.card-white-gray{
    background-color: #f8f8f8 !important;
}

.min-height-300{
    min-height: 300px;
}

/*****************/

/* Table Forum */

table.content-table-users {
    border-collapse: collapse;
    margin: 2em 0;
    table-layout: auto;
    width: 100%;
}

.content-table-users tr:nth-of-type(odd) {
    text-align: left;
    vertical-align: top;
    color: #2A426D;
    font-size: 13px;
}

.button-download-document {
    font-weight: 700;
    border-radius: 6px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    background: linear-gradient(275.61deg,#ab863d 21.43%,#b4904a 58.77%,#dbb977 122.13%)!important;
    border: 0px solid #f2af2b;
    color: #fff;
    padding: .8em 10px;
    cursor: pointer;
}

.content-table-users td:nth-child(1) {
    padding-right: 1em
}

.content-table-users td:nth-child(1) p {
    margin: 0.2em 0;
}

.content-table-users tr {
    border-bottom: 1px solid #EBEBEB;
}

.content-table-users thead tr {
    border-bottom: 0;
}

.content-table-users th {
    padding-bottom: 1em;
}

.content-table-users td {
    padding: 1em 0;
    font-size: 16px;
}

.content-table-users .table-users--description {
    font-size: 13px;
    color: #222;
}

.content-table-users .table-users--title {
    font-weight: bold;
    font-size: 17px;
    color: #000;
}

.content-table-users .table-users--date {
    color: #9098AC;
    font-size: 13px;
}

.content-table-users .table-users--number {
    color: #E88B19;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.content-table-users .table-users--general{
    font-size: 15px;
    color: #222;
}

.content-table-users .list-library__aditional {
    font-weight: bold;
}

.selectTrello .css-1wa3eu0-placeholder {
    color: #222;
    font-size: 14px;
}

.selectTrello .css-1pahdxg-control, .selectTrello .css-yk16xz-control {
    border: 0;
    background: transparent;
    font-size: 13px;
    margin-bottom: .25em;
    border-bottom: 1px solid #d3d5db;
    border-radius: 0;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
    table.content-table-users .content-table-usersthead,
    .content-table-users tbody,
    .content-table-users th,
    .content-table-users td,
    .content-table-users tr {
        display: block;
    }

    .content-table-users td {
        padding: 0.25em 0
    }

    .content-table-users td:last-child {
        padding-bottom: 2em;
    }

    .content-table-users td:nth-child(1) {
        width: auto;
        padding-right: 1em
    }

    .content-table-users th {
        display: none;
    }
    .content-table-users thead {
        display: none;
    }
}

/* STYLE 26 may 2022 */
.wrapper-input__col-four-size {
    width: 20%;
}

.wraper-input-full-width {
    width: 100%;
}

.wraper-input__responsive {
    flex-wrap: wrap;
}

@media screen and (max-width: 900px) {
    .wrapper-input__col-four-size {
        width: 45%;
    }
}

@media screen and (max-width: 750px) {
    .deliverables-wrapper-container.container {
        max-width: 100%;
    }
}


@media screen and (max-width: 600px) {
    .wrapper-input__col-four-size {
        width: 100%;
    }
}
