.chart-fake {
    width: 120px;
    height: 120px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.alert-message {
    padding: 0.5em;
    border: 1px solid #f2af2b;
    background: #fbeed4;
    line-height: 1.2em;
    color: #7f6126;
}

.dashboard-title {
    font-weight: 800 !important;
    font-size: 24px !important;
    margin: 0 0 1.5em !important;
    /* border-bottom: 1px solid #ddd !important; */
    /* padding-bottom: .5em !important; */
    position: relative;
    color: #0F3152;
}

.dashboard-title:before {
    content: '';
    position: absolute;
    top: calc(100% + 15px);
    left: 0;
    background: linear-gradient(90deg, #5E98AF 49.92%, #AB863D 50.64%, #AB863D 52.43%, #AB863D 56.38%);
    width: 200px;
    height: 3px;
}

.section-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    margin: 0 0 1.5em !important;
    border-bottom: 1px solid #ddd !important;
    padding-bottom: .5em !important;
}

.margin-top-20{
    margin-top: 20px;
}

.btn-primary{
    border-radius: 0 !important;
    border-color: #4b7fb5 !important;
    background-color: #4b7fb5 !important;
}

h2{
 color: #989898;
}

h4{
    color: #5f5f5f;
}

.grayish-text{
    color: #8e9ba2;
}

.card-body{
    background-color: #f6f6f6;
}

.card-footer{
    background-color: #d9d9d9 !important;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* Style 26 May 2022 */
.reports-card h4.section-title {
    margin-bottom: 0 !important;
}

.reports-link a:hover h4 {
    color: #809CB7 !important;
    text-decoration: none !important;
}

a h4, a:hover h4, .reports-link a:hover {
    text-decoration: none !important;
}